export const SCREENING_POSTER_SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
export const SCREENING_POSTER_MAX_SIZE = 5 * 1024 * 1024 // 5MB

export const SCREENING_STATUS_CREATED = 1
export const SCREENING_STATUS_PUBLISHED = 2
export const SCREENING_STATUS_EMAILS_SENT = 3
export const SCREENING_STATUS_FULL = 7
export const SCREENING_STATUS_CANCELLED = 8
export const SCREENING_STATUS_CANCEL_EMAILS_SENT = 9
export const SCREENING_STATUS_FINISHED = 10
export const SCREENING_STATUS_WAITING_LIST = 12

export const ATTENDANT_STATUS_CREATED = 0
export const ATTENDANT_STATUS_INVITED = 1
export const ATTENDANT_STATUS_DECLINED = 2
export const ATTENDANT_STATUS_ATTENDING = 3
export const ATTENDANT_STATUS_CANCELLED = 4
export const ATTENDANT_STATUS_ATTENDED = 5
export const ATTENDANT_STATUS_FAILED_TO_ATTEND = 6
export const ATTENDANT_STATUS_APPEALED = 7
export const ATTENDANT_STATUS_APPEAL_ACCEPTED = 8
export const ATTENDANT_STATUS_APPEAL_REJECTED = 9
export const ATTENDANT_STATUS_IN_WAITING_LIST = 12

export const SCREENING_ATTENDANT_FINISHED = 'finished'
export const SCREENING_ATTENDANT_ELIGIBLE_FOR_APPLY_WAITING_LIST
  = 'eligible_for_apply_waiting_list'
export const SCREENING_ATTENDANT_ELIGIBLE = 'eligible'
export const SCREENING_ATTENDANT_RSVPD_IN_WAITING_LIST = 'rsvpd_in_waiting_list'
export const SCREENING_ATTENDANT_RSVPD = 'rsvpd'
export const SCREENING_ATTENDANT_ALL_CAPACITIES_ARE_FILLED
  = 'all_capacities_are_filled'
export const SCREENING_ATTENDANT_NOT_ELIGIBLE = 'not_eligible'
export const SCREENING_ATTENDANT_NOT_AVAILABLE = 'not_available'
